import React, { useEffect, useState } from "react";
import styled from "styled-components";

import Footer from "../components/Footer";
import Nav from "../components/Nav";
import { useSelector } from "react-redux";
import RecipesLoading from "../components/recipes/RecipesLoading";
import useUserRecipes from "../hooks/useUserRecipes";
import { useMediaQuery } from "@mui/material";
import { ArrowDownward } from "@mui/icons-material";
import { Link } from "react-scroll";
import BackdropWrapper from "../components/modals/BackdropWrapper";
import ConvertToRecipes from "../components/modals/ConvertToRecipes";

function Favorites() {
  const [convertFileModal, setConvertFileModal] = useState(false);
  const uid = useSelector((state) => state.user.uid);
  const { convertedRecipes, favoriteRecipes } = useSelector(
    (state) => state.userRecipes
  );
  const fetchUserRecipes = useUserRecipes();
  const mediaQuery = useMediaQuery("(max-width: 600px)");

  async function fetchRecipesHandler(recipeTypes) {
    try {
      await fetchUserRecipes(recipeTypes);
    } catch (e) {
      console.log("error while fetching user recipes", e);
    }
  }


  function convertFilesBackdrop() {
    setConvertFileModal((current) => !current);
  }

  useEffect(() => {
    fetchRecipesHandler(["favorite", "converted"]);
  }, [uid]);


  return (
    <>
      <Nav />
      <StyledFavorites>
        {mediaQuery && (
          <Link
            activeClass="active"
            to="manual-recipes"
            spy={true}
            smooth={true}
            offset={50}
            duration={1000}
            className="arrow"
          >
            <ArrowDownward className="icon" />
          </Link>
        )}
        {/* <div className="home">
          <RecipeButtons title="My Favorites" />
        </div> */}
        <BackdropWrapper
          open={convertFileModal}
          handleBackdrop={convertFilesBackdrop}
          element={<ConvertToRecipes backdropHandler={convertFilesBackdrop} />}
        />
        <div className="heading">
          <h3>My Recipes</h3>
          <button onClick={convertFilesBackdrop} className="secondary-btn">
            Convert Files to Recipes
          </button>
        </div>

        <RecipesLoading
          notFoundMsg={"No saved recipes yet"}
          // data={{...favoriteRecipes, data: {...favoriteRecipes.data}}}
          data={structuredClone(favoriteRecipes)}
          title="Saved Recipes"
        />
        {/* <RecipesLoading
          className="web-recipes"
          data={webRecipes}
          title="Web Recipes"
        /> */}
        <RecipesLoading
          className="manual-recipes"
          data={structuredClone(convertedRecipes)}
          title="Converted Recipes"
          notFoundMsg={"No custom recipes yet"}
        />
      </StyledFavorites>
      <Footer />
    </>
  );
}

const StyledFavorites = styled.div`
  margin: var(--section-margin) auto;
  width: var(--tablet-width);
  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: var(--heading-margin);
  }
  .arrow {
    color: var(--secondary-color);
    position: absolute;
    top: 80px;
    right: 5%;
    z-index: 10;
    border-radius: 50px;
    box-shadow: 0px 1px 8px var(--shadow);
    padding: 6px 3px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      color: var(--secondary-color);
    }
  }
  .home {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    gap: 30px;
    margin-bottom: 20px;
    .home-buttons {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
      div {
        display: flex;
        gap: 20px;
        button {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          background-color: var(--secondary-color);
          &:hover {
            background-color: var(--secondary-dark-color);
          }
        }
      }
    }
    .tabs {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 30px;
      a {
        color: var(--text-color);
      }
    }
    .recipes {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      align-items: center;
      justify-content: space-between;
      margin: auto;
      position: relative;
      padding-top: 40px;
      min-height: 44vh;
      column-gap: 40px;
      /* .recipe1,
      .recipe3 {
        align-self: end;
      }
      .recipe2 {
        align-self: start;
      } */
    }
  }
`;

export default Favorites;
