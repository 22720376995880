import {
  Chip,
  ToggleButton,
  ToggleButtonGroup,
  useMediaQuery,
} from "@mui/material";
import { useRef, useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useEffect } from "react";
import LoadingButton from "./LoadingButton";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../store/userSlice";

export default function SearchableTabs({
  tabs,
  dietCategory,
  searchableTabsHandler,
  searchQuery,
  inputs,
  setInputs,
}) {
  const inputRef = useRef("");
  const [searchLoading, setSearchLoading] = useState(false);
  const mediaQuery600 = useMediaQuery("(max-width: 600px)");
  const [filtersOpen, setFiltersOpen] = useState(mediaQuery600 ? false : true);

  const preferences = useSelector((state) => state.user.preferences);
  const dispatch = useDispatch();

  const filterChangeHandler = async (event, value) => {
    setInputs((current) => ({
      ...current,
      [event.target.name]: value,
    }));
  };
  const maxReadyTimeHandler = (event, value) => {
    if (value === null) {
      value = 10000;
    }
    setInputs((current) => ({
      ...current,
      maxReadyTime: value,
    }));
  };

  function inputSearchHandler(e) {
    e.preventDefault();
    const value = inputRef.current.value;
    if (value !== "") {
      setInputs((current) => ({
        ...current,
        query: [...current.query, value],
      }));
    }
    inputRef.current.value = "";
  }

  function handleDelete(tagName) {
    setInputs((current) => ({
      ...current,
      query: current.query.filter((chip) => chip !== tagName),
    }));
  }

  function deleteUserFiltersHandler(filterInfo) {
    dispatch(userActions.deleteUserPreferences(filterInfo));
  }

  useEffect(() => {
    async function fetchRecipes() {
      setSearchLoading(true);
      await searchableTabsHandler(false);
      setSearchLoading(false);
    }
    fetchRecipes();
  }, [inputs]);

  const animationVariants = {
    open: {
      opacity: 1,
      height: "max-content",
      transition: { opacity: { delay: 0.2 } },
    },
    closed: {
      opacity: 0,
      height: "0px",
      transition: { opacity: { delay: -0.1 } },
    },
    upward: { rotate: 180, y: -4 },
  };

  return (
    <StyledTabs>
      <StyledRow1 filtersOpen={filtersOpen}>
        {searchQuery !== false && (
          <form className="search">
            <StyledSearch className="inputQuery-input-container">
              {inputs.query.map((tag, index) => (
                <Chip
                  label={tag}
                  key={index}
                  variant="outlined"
                  onDelete={(e) => handleDelete(tag)}
                />
              ))}
              <input
                onKeyDown={(e) => e.key === "Enter" && inputSearchHandler(e)}
                ref={inputRef}
                required
                type="text"
                className="tags-input"
                placeholder="What are you craving today?"
              />
            </StyledSearch>
            <LoadingButton
              loading={searchLoading}
              onClick={inputSearchHandler}
              type="submit"
              title="Search"
            />
          </form>
        )}
        <button
          className="filters"
          onClick={(e) => setFiltersOpen((prev) => !prev)}
        >
          Filters
          <motion.div
            initial={{ y: -1 }}
            animate={filtersOpen ? "upward" : "downward"}
            variants={animationVariants}
          >
            <KeyboardArrowDownIcon fontSize="small" />
          </motion.div>
        </button>
        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={filtersOpen ? "open" : "closed"}
          variants={animationVariants}
          className="filter-btns box"
        >
          <ToggleButtonGroup
            className="filter-btns"
            value={inputs.cuisines}
            onChange={filterChangeHandler}
          >
            {tabs.map(
              (tab, index) =>
                typeof tab.value === "string" && (
                  <ToggleButton
                    size="large"
                    aria-label={tab.name}
                    name="cuisines"
                    value={tab.value}
                    key={index}
                    selected={inputs.cuisines.includes(tab.value)}
                  >
                    {tab.name}
                  </ToggleButton>
                )
            )}
          </ToggleButtonGroup>
          <ToggleButtonGroup
            className="filter-btns"
            value={inputs.dietCategory}
            onChange={filterChangeHandler}
          >
            {dietCategory.map(
              (tab, index) =>
                typeof tab.value === "string" && (
                  <ToggleButton
                    size="large"
                    aria-label={tab.name}
                    name="dietCategory"
                    value={tab.value}
                    key={index}
                    selected={inputs.dietCategory.includes(tab.value)}
                  >
                    {tab.name}
                  </ToggleButton>
                )
            )}
          </ToggleButtonGroup>
          <ToggleButtonGroup
            exclusive
            aria-label="max-ready-time"
            value={inputs.maxReadyTime}
            onChange={maxReadyTimeHandler}
            className="filter-btns"
          >
            {tabs.map(
              (tab, index) =>
                typeof tab.value === "number" && (
                  <ToggleButton
                    size="large"
                    aria-label={tab.name}
                    name="maxReadyTime"
                    value={tab.value}
                    key={index}
                  >
                    {tab.name}
                  </ToggleButton>
                )
            )}
          </ToggleButtonGroup>
        </motion.div>
      </StyledRow1>
      <div className="row2">
        {preferences.cuisine_preferences &&
          preferences.cuisine_preferences.values.map(
            (cuisines, index) =>
              cuisines !== "" && (
                <Chip
                  label={cuisines}
                  key={index}
                  variant="outlined"
                  onDelete={(e) =>
                    deleteUserFiltersHandler({
                      filterName: "cuisine_preferences",
                      filterValue: cuisines,
                    })
                  }
                />
              )
          )}
        {preferences.diet_preferences &&
          preferences.diet_preferences.values.map(
            (diet) =>
              diet !== "" && (
                <Chip
                  label={diet}
                  key={1}
                  variant="outlined"
                  onDelete={(e) =>
                    deleteUserFiltersHandler({
                      filterName: "diet_preferences",
                      filterValue: diet,
                    })
                  }
                />
              )
          )}
      </div>
    </StyledTabs>
  );
}

const StyledTabs = styled.section`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: center;
  margin: 2% auto;
  span {
    font-size: 14px;
  }
  .row2 div {
    margin: 4px;
  }
`;

const StyledSearch = styled.div`
  padding: 0 .8rem;
  min-width: 400px;
  max-width: 700px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
  border: 2px solid var(--primary-color);
  border-radius: 50px;
  input{
    min-width: 300px;
    border: none;
    padding-left: 2px;
  }
   .MuiSvgIcon-root{
    color: var(--secondary-color) !important;
   }
  .tag-item {
    background-color: rgb(218, 216, 216);
    display: inline-block;
    padding: 0.5em 0.75em;
    border-radius: 20px;
  }
  .tag-item .close {
    height: 20px;
    width: 20px;
    background-color: rgb(48, 48, 48);
    color: #fff;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: 0.5em;
    font-size: 18px;
    cursor: pointer;
  }

  .tags-input {
    flex-grow: 1;
  }
  @media screen and (max-width: 600px) {
    min-width: 90vw;
    max-width: 90vw;
  }
`;

const StyledRow1 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 30px;
  row-gap: 15px;
  .search {
    display: flex;
    align-items: center;
    justify-content: inherit;
    flex-wrap: wrap;
    gap: 10px;
  }
  .filters {
    display: flex;
    justify-content: center;
    color: var(--secondary-color);
    background-color: transparent;
    padding: 0;
    min-width: 60px;
    &:hover {
      background-color: transparent;
    }
  }
  .filter-btns {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    &.box {
      pointer-events: ${(props) => !props.filtersOpen && "none"};
    }
    button {
      min-width: 60px;
      font-size: 12px;
      font-weight: 600;
    }
    .Mui-selected {
      color: white;
      background-color: var(--green-color) !important;
    }
  }
  @media (pointer: fine) {
    .filter-btns {
      button:hover {
        /* color: white; */
        color: var(--green-color);
      }
      .Mui-selected:hover {
        color: white;
      }
    }
  }
  @media screen and (max-width: 600px) {
    .filter-btns button {
      font-size: 10px;
    }
  }
`;
