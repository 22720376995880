import { deleteField, doc, updateDoc, writeBatch } from "firebase/firestore";
import { db } from "../authentication/firebase";
import { useDispatch, useSelector } from "react-redux";
import { mealPlanningActions } from "../store/mealPlanningSlice";
import { alertActions } from "../store/alertSlice";

export default function useDeleteMeal() {
  const uid = useSelector((state) => state.user.uid);
  const dispatch = useDispatch();

  return async function deleteMeal(meal, mealType, date) {
    const batch = writeBatch(db);
    try {
      const yy = date.split("-")[0];
      const mm = date.split("-")[1];
      const dd = date.split("-")[2];
      const mealRef = doc(db, `dailyMeals/${uid}/${yy}-${mm}`, dd);
      const mealsNutritionRef = doc(
        db,
        `mealsNutrition/${uid}/${yy}-${mm}`,
        dd
      );
      batch.update(mealRef, {
        [mealType]: deleteField(),
      });
      if (meal.nutrition) {
        batch.update(mealsNutritionRef, {
          [mealType]: deleteField(),
        });
      }
      await batch.commit();
      const cartRef = doc(db, `shoppingCart/${uid}/${yy}-${mm}`, dd);
      await updateDoc(cartRef, {
        [mealType]: deleteField(),
      });
  
      dispatch(mealPlanningActions.deleteMeal({ date, mealType }));
      dispatch(
        alertActions.setAlert({
          title: "Recipe deleted successfully",
          messageType: "success",
        })
      );
      window.dataLayer.push({
        event: "meal-deleted",
        recipeId: meal.id
      })
    } catch (e) {
      dispatch(
        alertActions.setAlert({
          title: "Failed to delete recipe",
          messageType: "error",
        })
      );
      console.log("error", e);
    }
  };
}
