import React from "react";
import { useState } from "react";
import { useRef } from "react";
import styled from "styled-components";
import LoadingButton from "../LoadingButton";
import CloseIcon from "@mui/icons-material/Close";
import firebaseApi from "../../utils/firebaseApi";
import { useDispatch } from "react-redux";
import { alertActions } from "../../store/alertSlice";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import { FaWhatsappSquare } from "react-icons/fa";

export default function ShareRecipe({ recipeData, handleBackdrop }) {
  const inputRef = useRef("");
  const [ingredientsLoading, setIngredientsLoading] = useState(false);
  const [recipeLoading, setRecipeLoading] = useState(false);

  const dispatch = useDispatch();

  async function shareIngredientsHandler(e) {
    e.preventDefault();
    if (inputRef.current.value === "") {
      dispatch(
        alertActions.setAlert({
          title: "Please enter valid email",
          messageType: "error",
        })
      );
      return;
    }
    try {
      setIngredientsLoading(true);
      const url = `/sendRecipeIngredientsToEmail?email=${inputRef.current.value}&recipeId=${recipeData.id}&recipeType=${recipeData.recipeType}&servings=${recipeData.savedServings}`;
      const res = await firebaseApi.get(url);
      console.log("ingredients email response", res.data);
      dispatch(
        alertActions.setAlert({
          title: "Email sent successfully",
          messageType: "success",
        })
      );
      handleBackdrop(false);
    } catch (e) {
      dispatch(
        alertActions.setAlert({
          title: "Failed to send email",
          messageType: "error",
        })
      );
      console.log("error while sharing ingredients", e);
    }
    setIngredientsLoading(false);
  }

  async function shareRecipeHandler(e) {
    e.preventDefault();
    if (inputRef.current.value === "") {
      dispatch(
        alertActions.setAlert({
          title: "Please enter valid email",
          messageType: "error",
        })
      );
      return;
    }
    try {
      setRecipeLoading(true);
      const url = `/sendRecipeToEmail?email=${inputRef.current.value}&recipeId=${recipeData.id}&recipeType=${recipeData.recipeType}&servings=${recipeData.savedServings}`;
      const res = await firebaseApi.get(url);
      console.log("recipe email response", res.data);
      dispatch(
        alertActions.setAlert({
          title: "Email sent successfully",
          messageType: "success",
        })
      );
      handleBackdrop(false);
    } catch (e) {
      dispatch(
        alertActions.setAlert({
          title: "Failed to send email",
          messageType: "error",
        })
      );
      console.log("error while sharing recipe", e);
    }
    setRecipeLoading(false);
  }
  const shareableLink = `https://www.breakbread.ai/explore?recipe-type=${
    recipeData.recipeType === "gpt" ? "ai" : recipeData.recipeType
  }&recipe-id=${recipeData.id}`;

  function copyTextHandler() {
    navigator.clipboard
      .writeText(shareableLink)
      .then(() => {
        dispatch(
          alertActions.setAlert({
            title: "Link Copied!",
            messageType: "success",
          })
        );
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  }

  return (
    <StyledSearch>
      <div className="heading">
        <h4>Share Recipe</h4>
        <button className="icon" onClick={(e) => handleBackdrop(false)}>
          {<CloseIcon htmlColor="#F79C16" fontSize="large" />}
        </button>
      </div>
      <div className="content">
        <h5>Share recipe with a friend</h5>
        <div className="icons">
          <div className="copy-link" onClick={copyTextHandler}>
            <CopyAllIcon />
            <p>Copy link</p>
          </div>
          <a
            href={`https://wa.me/?text=${encodeURIComponent(shareableLink)}`}
            target="black"
            className="whatsapp"
          >
            <FaWhatsappSquare />
            <p>Whatsapp</p>
          </a>
        </div>
        <div className="space">
          <div />
          <span>Or</span>
          <div />
        </div>
      </div>
      <div className="content">
        <h5>Send it to an email</h5>
        <form>
          <input type="email" required ref={inputRef} placeholder="Email" />
          <div className="btns">
            <LoadingButton
              loading={ingredientsLoading}
              type="submit"
              title="Share ingredients"
              onClick={shareIngredientsHandler}
            />
            <LoadingButton
              loading={recipeLoading}
              type="submit"
              title="Share recipe"
              className="secondary-btn"
              onClick={shareRecipeHandler}
            />
          </div>
        </form>
      </div>
    </StyledSearch>
  );
}

const StyledSearch = styled.section`
  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6px;
  }
  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 12px;
    .MuiInputBase-root {
      /* border-radius: 50px; */
    }
    input {
      min-width: 380px;
      border-radius: 50px;
      font-size: 16px;
      margin-bottom: 1rem;
    }
    label {
      font-size: 16px !important;
    }
    .btns {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;
    }
    .MuiAutocomplete-endAdornment {
      button {
        min-width: 20px;
      }
      svg {
        width: 1.6em;
        height: 1.6em;
      }
    }
    .icons {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      gap: 3%;
      width: 100%;
      text-align: center;
      p {
        white-space: nowrap;
      }
      svg {
        width: 40px;
        height: 40px;
      }
      .copy-link,
      .whatsapp {
        cursor: pointer;
        padding: 4px;
        transition: all 0.2s ease-in-out;
        font-weight: 300;
        line-height: 1;
        font-family: "Lato", sans-serif;
        &:hover {
          background-color: #d3d3d375;
          border-radius: 8px;
          transform: scale(1.04);
          p {
            color: var(--text-color);
          }
        }
      }
      .whatsapp {
        svg {
          color: #65d072;
        }
      }
      .copy-link {
        svg {
          border-radius: 4px;
          width: 36px;
          height: 36px;
          background-color: lightgray;
        }
      }
    }
    .space {
      display: flex;
      width: 40%;
      align-items: center;
      justify-content: center;
      margin-bottom: 6px;
      div {
        width: 50%;
        background-color: var(--text-light-color);
        height: 1px;
        z-index: -1;
      }
      span {
        background-color: white;
        z-index: 10;
        padding: 0 4px;
      }
    }
    @media screen and (max-width: 600px) {
      input {
        min-width: 280px;
      }
    }
  }
`;
